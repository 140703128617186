export const login = (user) => {
  return {
    type: 'LOGIN',
    payload: user,
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
};

export const selectApp = (app) => {
  return {
    type: 'SELECT_APP',
    payload: app,
  };
};

export const clearApp = () => {
  return {
    type: 'CLEAR_APP',
  };
};

//
export const setToast = (toast) => {
  return {
    type: 'SET_TOAST',
    payload: toast,
  };
};
