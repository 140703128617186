import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ children, ...rest }) => {
     const { user, currentApp } = useSelector(state => state);
     const isLoggedIn = user && currentApp;
     return isLoggedIn ? <Route {...rest} /> : <Redirect to="/login" />;
};

export default AuthRoute;
