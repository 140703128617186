import { combineReducers } from 'redux';
import userReducer from './userReducer';
import {
  sidebarShow,
  appReducer,
  appsReducer,
  toastReducer,
} from './commonReducer';

const rootReducer = combineReducers({
  sidebarShow,
  currentApp: appReducer,
  user: userReducer,
  apps: appsReducer,
  toast: toastReducer,
});

export default rootReducer;
