import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { icons } from "./assets/icons";
import store from "./store";
import { RecoilRoot } from "recoil";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_es from "src/translations/es/common.json";
import common_en from "src/translations/en/common.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("selectedLanguage") || "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    es: {
      common: common_es,
    },
  },
});

React.icons = icons;

ReactDOM.render(
  <RecoilRoot>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </RecoilRoot>,
  document.getElementById("root")
);

serviceWorker.unregister();
