import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";

import { HashRouter, Route, Switch } from "react-router-dom";

import AuthRoute from "./AuthRoute";
import React, { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import useRestoreAuthDetails from "./components/RestoreAuthDetails";
import { useDispatch } from "react-redux";
import { logout } from "src/actions/userActions.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  // Restore Auth Details form Custom Hook
  useRestoreAuthDetails();

  const dispatch = useDispatch();
  const logoutTimer = useRef(null);

  const resetTimer = () => {
    if (logoutTimer.current) clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(() => {
      dispatch(logout());
    }, 15 * 60 * 1000);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);
      resetTimer(); // Initialize timer on component mount
    }

    return () => {
      // Cleanup when component unmounts
      if (typeof window !== "undefined") {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
      }
    };
  }, [dispatch]);

  return (
    <div>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <AuthRoute
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
          </Switch>
        </React.Suspense>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </HashRouter>
    </div>
  );
};

export default App;
