import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login, selectApp } from 'src/actions';

const useRestoreAuthDetails = () => {
  const dispatch = useDispatch();

  const app = localStorage.getItem('@app')
    ? localStorage.getItem('@app')
    : null;

  const user = localStorage.getItem('@kuposuser')
    ? JSON.parse(localStorage.getItem('@kuposuser'))
    : null;

  const isLoggedIn = user && app;

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(login(user));
      dispatch(selectApp(app));
    }
  }, [isLoggedIn, dispatch, app, user]);

  return null;
};

export default useRestoreAuthDetails;
