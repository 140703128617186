const initialState = {
  sidebarShow: false,
};

export const sidebarShow = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

export const appReducer = (state = null, { type, payload }) => {
  switch (type) {
    case 'SELECT_APP':
      return payload;
    case 'CLEAR_APP':
      return null;
    default:
      return state;
  }
};

const initialAppState = [
  {
    id: 1,
    title: 'carpool',
    selected: false,
  },
  {
    id: 15,
    title: 'admin',
    selected: false,
  },
];

export const appsReducer = (state = initialAppState, { type, payload }) => {
  switch (type) {
    case 'ADD_APP':
      return payload;
    default:
      return state;
  }
};

export const toastReducer = (
  state = { show: false, msg: '', color: '' },
  { type, payload }
) => {
  switch (type) {
    case 'SET_TOAST':
      return payload;
    default:
      return state;
  }
};
